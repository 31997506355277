import React from 'react';

const LogoIcon = () => {
  return (
    <svg
      viewBox="0 0 182 106"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: '42',
        height: 'auto',
        transform: 'scale(1.2)',
        marginLeft: '-10',
      }}
    >
      <g clipPath="url(#a)">
        <path
          d="M60.108 97.219h-10.33v-4.998h10.33c1.76 0 2.713.857 2.713 2.428 0 1.618-.952 2.57-2.713 2.57zm-10.33-15.898h9.568c1.618 0 2.666.952 2.666 2.523 0 1.618-1.048 2.475-2.666 2.475H49.78V81.32zm15.185 7.187c3.141-.714 4.95-3.046 4.95-6.33 0-3.999-3.284-6.855-9.282-6.855h-18.85v27.989h19.802c5.665 0 9.472-3.38 9.472-8.235 0-3.38-2.427-5.807-6.092-6.569zm24.561 15.423c9.472 0 14.423-4.665 14.423-12.662V75.323h-8.188v15.85c0 3.285-2.427 5.38-6.235 5.38-3.95 0-6.188-2.095-6.188-5.38v-15.85h-8.187V91.27c0 7.997 5.569 12.662 14.375 12.662zM56.538 66.422c8.187 0 13.804-4.331 14.327-11.804H62.63c-.333 2.427-2.332 4.426-6.33 4.426-4.855 0-7.378-2.95-7.378-7.235 0-4.426 2.332-7.282 7.283-7.282 3.998 0 6.092 2.047 6.425 4.57h8.235c-.333-6.665-5.188-11.9-14.85-11.9-9.378 0-15.28 5.569-15.28 14.755 0 9.092 6.283 14.47 15.803 14.47zm66.305 37.509c9.044 0 13.471-3.475 13.471-9.092 0-4.903-3.142-7.806-9.663-8.663l-3.189-.428c-4.855-.667-6.521-1.048-6.521-2.523 0-1.285 1.476-2.142 5.045-2.142 4.38 0 8.902.856 12.233 1.904v-6.474c-2.332-.952-6.521-1.808-11.471-1.808-9.091 0-13.804 3.427-13.804 9.044 0 5.14 3.713 7.663 10.091 8.663l3.38.523c4.331.666 5.807 1.19 5.807 2.427 0 1.19-1.19 2.19-4.855 2.19-4.522 0-9.567-1.19-13.804-2.951v6.807c3.189 1.332 7.807 2.523 13.28 2.523zM82.574 65.804H98.33v-6.807H85.383c-1.428 0-2.047-.619-2.047-2.047V37.815h-8.187v20.563c0 4.38 3.046 7.426 7.425 7.426zm21.373 0h8.186V37.815h-8.186v27.989zm28.559.618c8.187 0 13.804-4.331 14.327-11.804h-8.234c-.334 2.427-2.333 4.426-6.331 4.426-4.855 0-7.378-2.95-7.378-7.235 0-4.426 2.332-7.282 7.283-7.282 3.998 0 6.092 2.047 6.426 4.57h8.234c-.333-6.665-5.188-11.9-14.851-11.9-9.377 0-15.279 5.569-15.279 14.755 0 9.092 6.283 14.47 15.803 14.47zm18.992-.618h7.997v-8.711c1.808-.619 3.617-1.38 5.283-2.333 3.142 3.713 4.855 6.855 6.426 11.044h8.949c-1.571-5.236-5.474-12.09-9.187-15.47 3.808-3.38 6.664-7.568 8.235-12.519h-8.425c-1.476 4.617-5.807 9.377-11.281 11.662V37.815h-7.997v27.989z"
          fill="#fff"
        />
        <path
          d="M2.941 54.665h27.036c.714 0 1.19-.523 1.19-1.19v-1.19c0-15.564 9.187-24.275 25.466-24.275h101.672c.714 0 1.19-.476 1.19-1.19V1.259c0-.714-.476-1.19-1.19-1.19H51.54C21.743.07.847 17.966.847 43.432c0 3.95.19 7.14.666 9.9.143.81.571 1.333 1.428 1.333z"
          fill="#A528FF"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            transform="translate(.847)"
            d="M0 0h179.306v104H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
