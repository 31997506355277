import {
  createUnifiedTheme,
  genPageTheme,
  shapes,
  palettes,
} from '@backstage/theme';

const clickBusPalette = {
  light: {
    ...palettes.light,
    primary: {
      main: '#5d1499',
    },
    secondary: {
      main: '#FDCA5F',
    },
    error: {
      main: '#a0273f',
    },
    warning: {
      main: '#bb7e21',
    },
    info: {
      main: '#14a9d2',
    },
    success: {
      main: '#59ac00',
    },
    background: {
      default: '#ececec',
      paper: '#e5e5e5',
    },
    banner: {
      info: '#34548a',
      error: '#8c4351',
      text: '#343b58',
      link: '#565a6e',
    },
    errorBackground: '#8c4351',
    warningBackground: '#8f5e15',
    infoBackground: '#343b58',
    navigation: {
      background: '#390b5e',
      indicator: '#ffd000',
      color: '#a1a1a1',
      selectedColor: '#ffd000',
      navItem: {
        hoverBackground: '#5c3283',
      },
    },
  },
  dark: {
    ...palettes.dark,
    primary: {
      main: '#d5b3f5',
    },
    secondary: {
      main: '#FDCA5F',
    },
  },
};

const pageTheme = {
  home: genPageTheme({ colors: ['#31005a', '#6100b0'], shape: shapes.wave }),
  documentation: genPageTheme({
    colors: ['#31005a', '#6100b0'],
    shape: shapes.wave2,
  }),
  tool: genPageTheme({ colors: ['#31005a', '#6100b0'], shape: shapes.round }),
  service: genPageTheme({
    colors: ['#31005a', '#6100b0'],
    shape: shapes.wave,
  }),
  website: genPageTheme({
    colors: ['#31005a', '#6100b0'],
    shape: shapes.wave,
  }),
  library: genPageTheme({
    colors: ['#31005a', '#6100b0'],
    shape: shapes.wave,
  }),
  other: genPageTheme({ colors: ['#31005a', '#6100b0'], shape: shapes.wave }),
  app: genPageTheme({ colors: ['#31005a', '#6100b0'], shape: shapes.wave }),
  apis: genPageTheme({ colors: ['#31005a', '#6100b0'], shape: shapes.wave }),
};

export const clickBusTheme = {
  light: createUnifiedTheme({ palette: clickBusPalette.light, pageTheme }),
  dark: createUnifiedTheme({ palette: clickBusPalette.dark, pageTheme }),
};
