import React from 'react';

const LogoFull = () => {
  return (
    <svg
      viewBox="0 0 262 64"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="logo-svg"
      className="css-1ajdo0y"
      style={{ width: 170, height: 'auto' }}
    >
      <path
        d="M179.142 56.241v-4.66h9.649c1.646 0 2.535.799 2.535 2.263 0 1.51-.889 2.397-2.535 2.397h-9.649zm-7.471 5.682h18.498c5.293 0 8.852-3.151 8.852-7.679 0-3.151-2.268-5.415-5.693-6.125 2.936-.666 4.625-2.841 4.625-5.904 0-3.728-3.07-6.392-8.673-6.392h-17.609v26.1zm7.471-15.846v-4.66h8.938c1.513 0 2.491.887 2.491 2.352 0 1.509-.978 2.308-2.491 2.308h-8.938zM216.274 62.5c8.849 0 13.476-4.35 13.476-11.807v-14.87h-7.65v14.781c0 3.063-2.267 5.016-5.826 5.016-3.691 0-5.782-1.953-5.782-5.016v-14.78h-7.647v14.869c0 7.457 5.203 11.807 13.429 11.807zm-163.91 0c7.648 0 12.896-4.04 13.385-11.008h-7.693c-.312 2.264-2.18 4.128-5.915 4.128-4.536 0-6.893-2.752-6.893-6.747 0-4.128 2.18-6.791 6.804-6.791 3.736 0 5.692 1.909 6.004 4.261h7.693c-.311-6.214-4.847-11.097-13.874-11.097-8.761 0-14.275 5.194-14.275 13.76 0 8.478 5.87 13.494 14.764 13.494zm195.039 0c8.449 0 12.585-3.24 12.585-8.478 0-4.572-2.934-7.28-9.026-8.078l-2.98-.4c-4.538-.621-6.092-.976-6.092-2.352 0-1.199 1.378-1.998 4.713-1.998 4.091 0 8.316.8 11.43 1.776v-6.037c-2.181-.888-6.095-1.687-10.719-1.687-8.494 0-12.895 3.196-12.895 8.434 0 4.794 3.467 7.146 9.428 8.078l3.156.488c4.046.622 5.424 1.11 5.424 2.264 0 1.11-1.11 2.042-4.535 2.042-4.224 0-8.938-1.11-12.897-2.752v6.347c2.98 1.243 7.294 2.353 12.408 2.353zm-170.714-.577h14.72v-6.347H79.312c-1.335 0-1.913-.577-1.913-1.91V35.824h-7.648V55c0 4.083 2.846 6.924 6.937 6.924zm19.967 0h7.648v-26.1h-7.648v26.1zm26.682.577c7.65 0 12.895-4.04 13.384-11.008h-7.691c-.313 2.264-2.181 4.128-5.916 4.128-4.535 0-6.892-2.752-6.892-6.747 0-4.128 2.178-6.791 6.805-6.791 3.735 0 5.69 1.909 6.003 4.261h7.691c-.31-6.214-4.845-11.097-13.873-11.097-8.762 0-14.276 5.194-14.276 13.76 0 8.478 5.872 13.494 14.765 13.494zm17.743-.577h7.471V53.8c1.691-.577 3.38-1.287 4.937-2.175 2.936 3.462 4.535 6.392 6.003 10.298h8.36c-1.466-4.883-5.114-11.274-8.581-14.426 3.556-3.151 6.224-7.057 7.691-11.674h-7.87c-1.378 4.306-5.424 8.745-10.54 10.875V35.823h-7.471v26.1z"
        fill="#ffffff"
      />
      <path
        d="M2.29 51.536h25.26c.666 0 1.11-.488 1.11-1.11v-1.109c0-14.514 8.583-22.637 23.793-22.637h94.988c.666 0 1.11-.444 1.11-1.11V1.734c0-.665-.444-1.11-1.11-1.11H47.694C19.856.625.334 17.315.334 41.062c0 3.684.177 6.658.622 9.233.133.754.534 1.242 1.334 1.242z"
        fill="#A528FF"
      />
    </svg>
  );
};

export default LogoFull;
